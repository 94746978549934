<template>
  <flat-pickr
    v-bind:disabled="disabled"
    class="input form-control pointer"
    :config="config"
    v-model="localValue"
  ></flat-pickr>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import l10n from 'flatpickr/dist/l10n/index';
export default {
  props: {
    time: {
      type: Boolean,
      default: true
    },
    defaultHour: {
      type: Number,
      default: new Date().getHours()
    },
    defaultMinute: {
      type: Number,
      default: new Date().getMinutes()
    },
    value: {
      type: [Date, String, Object],
      default: function() {
        return new Date();
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {};
  },
  computed: {
    config: function() {
      return {
        enableTime: false,
        dateFormat: 'd.m.Y',
        locale: l10n[this.$i18n.locale]
        // defaultHour: this.defaultHour,
        // defaultMinute: this.defaultMinute
      };
    },
    localValue: {
      get: function() {
        // console.log('get localValue');
        return this.value
          ? this.$moment(new Date(this.value)).format('DD.MM.YYYY')
          : null;
      },
      set: function(v) {
        // console.log('set localValue');
        const newVal = new Date(this.$moment(v, 'DD.MM.YYYY'));
        const oldVal = new Date(this.value);
        if (newVal.getTime() === oldVal.getTime()) {
          return;
        }
        this.$emit('input', newVal);
      }
    }
  },
  components: {
    flatPickr
  }
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
