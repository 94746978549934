<template>
    <nav class="navbar navbar-light navbar-expand-md d-flex flex-wrap nav-fill">
        <button class="navbar-toggler" @click="isMenu=!isMenu">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon"></span>
        </button>
        <div :class="{collapse: !isMenu}" class="navbar-collapse flex-grow-1 flex-shrink-1 justify-content-around">
            <ul class="nav navbar-nav flex-grow-1 justify-content-around">
                <router-link tag="li" class="nav-item" to="/interpreters/giveOut">
                    <a class="nav-link">Выдать</a>
                </router-link>
                <router-link tag="li" class="nav-item" to="/interpreters/giveIn">
                    <a class="nav-link">Вернуть</a>
                </router-link>

                <router-link tag="li" class="nav-item" to="/interpreters/stat">
                    <a class="nav-link">Зарегистрированы</a>
                </router-link>
                <router-link tag="li" class="nav-item" to="/interpreters/issued">
                    <a class="nav-link">Выданы</a>
                </router-link>
                <router-link tag="li" class="nav-item" to="/interpreters/willReturn">
                    <a class="nav-link">Возвраты</a>
                </router-link>
                <router-link tag="li" class="nav-item" to="/index">
                    <a class="nav-link">Главное меню</a>
                </router-link>
            </ul>
        </div>
    </nav>

</template>

<script>
  export default {
    name: 'InterpretersMenu',
    data: function () {
      return {
        isMenu: false
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
