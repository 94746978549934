<template>
    <nav class="navbar navbar-light navbar-expand-md d-flex flex-wrap nav-fill">
        <button class="navbar-toggler" @click="isMenu=!isMenu">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon"></span>
        </button>
        <div :class="{collapse: !isMenu}" class="navbar-collapse flex-grow-1 flex-shrink-1 justify-content-around">
            <ul class="nav navbar-nav flex-grow-1 justify-content-around">
                <template v-if="($isActive() || isPermited(4)) && (isPermited(1) || isPermited(2))">
                    <router-link tag="li" class="nav-item" to="/index">
                        <a class="nav-link"><i class="fa fa-search"></i></a>
                    </router-link>
                    <router-link tag="li" class="nav-item" to="/registered">
                        <a class="nav-link">Зарегистрированы</a>
                    </router-link>
                    <router-link tag="li" class="nav-item" to="/money">
                        <a class="nav-link">Сдать кассу</a>
                    </router-link>
                </template>
                <template v-if="($isActive() || isPermited(4)) && isPermited(7) ">
                    <router-link tag="li" class="nav-item" to="/checkExpress">
                        <a class="nav-link">Экспресс регистрация</a>
                    </router-link>
                    <router-link tag="li" class="nav-item" to="/expressStatistic">
                        <a class="nav-link">Экспресс статистика</a>
                    </router-link>
                </template>

                <!--
                <router-link tag="li" v-if="isPermited(4)"  class="nav-item" :to="{name: 'prepay'}">
                    <a class="nav-link">Внесение предоплаты</a>
                </router-link>
                -->

                <router-link v-if="isPermited(5)" tag="li" class="nav-item" to="/interpreters">
                    <a class="nav-link">Перевод</a>
                </router-link>
                <router-link v-if="isPermited(6)" tag="li" class="nav-item" to="/stat">
                    <a class="nav-link">Статистика ретрита</a>
                </router-link>
                <router-link v-if="isPermited(8)" tag="li" class="nav-item" to="/double/index">
                    <a class="nav-link">Дубликаты</a>
                </router-link>
                <router-link v-if="isPermited(8)" tag="li" class="nav-item" :to="{name: 'adminIndex'}">
                    <a class="nav-link">Админ меню</a>
                </router-link>
                <li class="nav-item" @click="logout"><a class="nav-link" name="logout" style="cursor: pointer">Выход</a></li>
            </ul>
        </div>
    </nav>
</template>

<script>
    // 5469
  export default {
    data: function() {
      return {
        isMenu: false
      }
    },
    methods: {
      logout() {
        this.$session.destroy();
        this.$router.push('/login');
      }
    },
    watch: {
      '$route' (/* to, from */) {
        this.isMenu = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import '~bootstrap/scss/bootstrap.scss';
    .nav-item{
        @extend .text-sm-left, .text-md-center, .text-lg-center, .text-xl-center;
        @extend .pl-sm-2;

    }
</style>
