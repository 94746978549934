<template>
    <div id="app" v-cloak>

        <div class="info" v-if="$session.exists() && menu">
            {{name()}}, <b>{{retreatName()}}</b>, <u>{{money()}}</u>
        </div>
        <main-menu v-if="menu() === 'main'"></main-menu>
        <stat-menu v-if="menu() === 'stat'"></stat-menu>
        <double-menu v-if="menu() === 'double'"></double-menu>
        <admin-menu v-if="menu() === 'admin'"></admin-menu>
        <interpreters-menu v-if="menu()==='interpreters'"></interpreters-menu>

        <br/>
        <router-view/>
    </div>
</template>

<style lang="scss">
    .nav-item {
        border: 1px dotted;
        border-radius: 5px;
    }

    .info {
        text-align: center;
        font-family: "Courier New", "Times New Roman Cyr";
        font-size: 16px
    }

    .warn {
        color: red;
        text-align: center;
        font-size: 16px;
    }

    [v-cloak] > * { display:none; }

</style>

<script>
  import StatMenu from './components/menu/stat';
  import MainMenu from './components/menu/main';
  import AdminMenu from './components/menu/admin';
  import InterpretersMenu from './components/menu/interpreters';
  import DoubleMenu from './components/menu/double';

  export default {
    data: function () {
      return {
      }
    },
    methods: {
      fullname() {
        return this.$session.get('fullname');
      },
      name() {
        return this.$session.get('name');
      },
      retreatName() {
        return this.$session.get('rtrname') || '';
      },
      money() {
        return this.$session.get('money');
      },
      menu() {
        if (!this.$session.exists()) {
          return null;
        }
        return this.$route.meta.menu;
      }
    },
    mounted() {
    },

    components: {
      InterpretersMenu,
      StatMenu,
      MainMenu,
      AdminMenu,
      DoubleMenu
    }

  }
</script>
