import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/express/full/confirmation/:uuid',
      name: 'expressFullConfirmation',
      component: () => import(/* webpackChunkName: "express" */ './views/express/full/confirmation.vue'),
      meta: { isPublic: true }
    },
    {
      path: '/express',
      name: 'express',
      meta: { isPublic: true },
      redirect: '/express/login',

      // component: () => import(/* webpackChunkName: "express" */ './views/public/express.vue')
      component: () => import(/* webpackChunkName: "express" */ './views/express/index.vue'),
      children: [
        {
          path: 'login',
          name: 'expressLogin',
          component: () => import(/* webpackChunkName: "express" */ './views/express/login.vue'),
          meta: { isPublic: true }

        },
        {
          path: 'active',
          name: 'expressActive',
          component: () => import(/* webpackChunkName: "express" */ './views/express/active.vue'),
          meta: { isPublic: true }

        },
        {
          path: 'prereg',
          name: 'expressPrereg',
          component: () => import(/* webpackChunkName: "express" */ './views/express/prereg.vue'),
          meta: { isPublic: true }
        },
        {
          path: 'full/index',
          name: 'expressFullIndex',
          component: () => import(/* webpackChunkName: "express" */ './views/express/full/index.vue'),
          meta: { isPublic: true }

        },
        {
          path: 'full/step1',
          name: 'expressFullStep1',
          component: () => import(/* webpackChunkName: "express" */ './views/express/full/step1.vue'),
          meta: { isPublic: true }

        },
        {
          path: 'full/step2',
          name: 'expressFullStep2',
          component: () => import(/* webpackChunkName: "express" */ './views/express/full/step2.vue'),
          meta: { isPublic: true }
        }
      ]
    },
    {
      path: '/approved',
      name: 'approved',
      meta: { isPublic: true },
      component: () => import(/* webpackChunkName: "public" */ './views/express/approved.vue')
    },
    {
      path: '/declined',
      name: 'declined',
      meta: { isPublic: true },
      component: () => import(/* webpackChunkName: "public" */ './views/express/declined.vue')
    },
    {
      path: '/checkExpress',
      name: 'checkExpress',
      meta: { menu: 'main' },
      component: () => import(/* webpackChunkName: "checkDay" */ './views/checkExpress.vue')
    },
    {
      path: '/checkExpress/:uid',
      name: 'checkExpressDetails',
      meta: { menu: 'main' },
      component: () => import(/* webpackChunkName: "checkDay" */ './views/checkExpressDetails.vue')
    },
    {
      path: '/expressStatistic',
      name: 'expressStatistic',
      meta: { menu: 'main' },
      component: () => import(/* webpackChunkName: "checkDay" */ './views/expressStatistic.vue')
    },
    {
      path: '/index',
      name: 'index',
      component: () => import(/* webpackChunkName: "registrator" */'./views/Index.vue'),
      meta: { menu: 'main' }
    },
    {
      path: '/user/:uid',
      name: 'User',
      meta: { menu: 'main' },
      component: () => import(/* webpackChunkName: "registrator" */ './views/user.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: { isPublic: true, menu: null },
      component: () => import(/* webpackChunkName: "public" */ './views/login.vue')
    },
    {
      path: '/registered',
      name: 'registered',
      meta: { menu: 'main' },
      component: () => import(/* webpackChunkName: "registrator" */ './views/registered.vue')
    },
    {
      path: '/money',
      name: 'money',
      meta: { menu: 'main' },
      component: () => import(/* webpackChunkName: "registrator" */ './views/money.vue')
    },
    {
      path: '/reg/:uid',
      name: 'reg',
      meta: { menu: 'main' },
      component: () => import(/* webpackChunkName: "registrator" */ './views/reg.vue')
    },
    {
      path: '/userEdit/:uid',
      name: 'userEdit',
      meta: { menu: 'main' },
      component: () => import(/* webpackChunkName: "registrator" */ './components/user/userEdit.vue')
    },
   // {
   //    path: '/prepay',
   //    name: 'prepay',
   //    component: () => import(/* webpackChunkName: "admin" */'./views/prepay.vue'),
   //    meta: {menu: 'main'},
   //  },
    {
      path: '/admin',
      name: 'adminIndex',
      // redirect: '/admin/index',
      component: () => import(/* webpackChunkName: "admin" */'./views/admin/index.vue'),
      meta: { menu: 'admin' },
      children: [
        {
          path: 'admins',
          name: 'admins',
          component: () => import(/* webpackChunkName: "admin" */ './views/admin/admins/list.vue'),
          meta: { menu: 'admin' }
        },
        {
          path: 'editAdmin/:id',
          name: 'editAdmin',
          component: () => import(/* webpackChunkName: "admin" */ './views/admin/admins/editAdmin.vue'),
          meta: { menu: 'admin' }
        },
        {
          path: 'retrts',
          name: 'retrts',
          component: () => import(/* webpackChunkName: "admin" */ './views/admin/retrts.vue'),
          meta: { menu: 'admin' }
        },
        {
          path: 'retrts/:id',
          name: 'retEdit',
          component: () => import(/* webpackChunkName: "admin" */ './views/admin/retEdit.vue'),
          meta: { menu: 'admin' }
        },
        {
          path: 'bases',
          name: 'bases',
          component: () => import(/* webpackChunkName: "admin" */ './views/admin/bases.vue'),
          meta: { menu: 'admin' }
        },
        {
          path: 'codes',
          name: 'codes',
          component: () => import(/* webpackChunkName: "admin" */ './views/admin/codes.vue'),
          meta: { menu: 'admin' }
        },
        {
          path: 'discounts',
          name: 'discounts',
          component: () => import(/* webpackChunkName: "admin" */ './views/admin/discounts/list.vue'),
          meta: { menu: 'admin' }
        },
        {
          path: 'discount/:id',
          name: 'discountEdit',
          component: () => import(/* webpackChunkName: "admin" */ './views/admin/discounts/edit.vue'),
          meta: { menu: 'admin' }
        }
      ]
    },
    {
      path: '/interpreterServiceStat',
      name: 'interpreterServiceStatistic',
      component: () => import(/* webpackChunkName: "stat" */ './views/interpreters/stat'),
      meta: { menu: 'main' }
    },
    {
      path: '/interpreters',
      name: 'interpretersIndex',
      component: () => import(/* webpackChunkName: "interpreters" */ './views/interpreters/index.vue'),
      meta: { menu: 'interpreters' },
      children: [
        {
          path: 'giveOut',
          name: 'interpretersGiveOut',
          component: () => import(/* webpackChunkName: "stat" */ './views/interpreters/giveOut.vue'),
          meta: { menu: 'interpreters' }
        },
        {
          path: 'giveIn',
          name: 'interpretersGiveIn',
          component: () => import(/* webpackChunkName: "stat" */ './views/interpreters/giveIn.vue'),
          meta: { menu: 'interpreters' }
        },
        {
          path: 'stat',
          name: 'interpretersStat',
          component: () => import(/* webpackChunkName: "stat" */ './views/interpreters/stat.vue'),
          meta: { menu: 'interpreters' }
        },
        {
          path: 'issued',
          name: 'issued',
          component: () => import(/* webpackChunkName: "stat" */ './views/interpreters/issued.vue'),
          meta: { menu: 'interpreters' }
        },
        {
          path: 'willReturn',
          name: 'willReturn',
          component: () => import(/* webpackChunkName: "stat" */ './views/interpreters/willReturn.vue'),
          meta: { menu: 'interpreters' }
        }
      ]
    },
    {
      path: '/stat',
      // name: 'general',
      component: () => import(/* webpackChunkName: "stat" */ './views/stat/index.vue'),
      meta: { menu: 'stat' },
      children: [
        {
          path: 'general',
          // name: 'general',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/general.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'invoices',
          name: 'invoices',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/invoices.vue'),
          meta: { menu: 'stat' }
        },
        // {
        //   path: 'select',
        //   name: 'statSelect',
        //   component: () => import (/* webpackChunkName: "stat" */'./views/stat/select'),
        //   meta: { menu: 'stat' }
        // },
        {
          path: 'byAge',
          name: 'byAge',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/byAge.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'prereg',
          name: 'statPrereg',
          component: () => import(/* webpackChunkName: "stat" */'./views/stat/prereg'),
          meta: { menu: 'stat' }
        },
        {
          path: 'moneyTransfer',
          name: 'moneyTransfer',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/moneyTransfer.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'users',
          name: 'users',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/users.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'countries',
          name: 'countries',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/countries.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'cities',
          name: 'cities',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/cities.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'churches',
          name: 'churches',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/churches.vue'),
          meta: { menu: 'stat' }
        },
        // {
        //   path: 'commanda',
        //   name: 'commanda',
        //   component: () => import(/* webpackChunkName: "stat" */ './views/stat/__commanda.vue'),
        //   meta: {menu: 'stat'},
        // },
        {
          path: 'discountMoney',
          name: 'discountMoney',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/showData.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'accommodataions',
          name: 'accommodataions',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/accommodataions.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'showLated',
          name: 'showLated',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/showData.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'showBorg',
          name: 'showBorg',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/showData.vue'),
          meta: { menu: 'stat' }
        },
        {
          path: 'queue',
          name: 'queue',
          component: () => import(/* webpackChunkName: "stat" */ './views/stat/queue.vue'),
          meta: { menu: 'stat' }
        }
      ]
    },
    {
      path: '/double/*',
      name: 'double',
      component: () => import(/* webpackChunkName: "double" */ './views/double.vue'),
      meta: { menu: 'double' }
    },
    {
      path: '*',
      redirect: { name: 'index' }
    }
  ]
});

// router.beforeEach((to, from, next) => {
//   console.log(to);
//   next();
// });
export default router;
