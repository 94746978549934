// import devtools from '@vue/devtools'
import Vue from 'vue';
import App from './App.vue';
import router from './router';

import BootstrapVue from 'bootstrap-vue';
import VueResource from 'vue-resource';
import VueSession from 'vue-session';
import VeeValidate, { Validator } from 'vee-validate';
import ru from 'vee-validate/dist/locale/ru';
import moment from 'moment-timezone';
import VueClipboard from 'vue-clipboard2';
import VueLoading from 'vuejs-loading-plugin';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './main.scss';
import settings from './settings';
import dateComponent from '@/components/date';
import VueI18n from 'vue-i18n';

Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(VueSession, { persist: true });

Validator.localize('ru', ru);
Vue.use(VeeValidate);

moment.tz.setDefault(moment.tz.guess());
Vue.prototype.$moment = moment;

Vue.use(VueClipboard);

Vue.use(VueLoading);
Vue.config.productionTip = false;

Vue.mixin({
  data: function () {
    return {
      token: undefined,
      socket: this.$root.socket
    };
  },
  methods: {
    getData(url, data) {
      const headers = this.getHeaders();
      if (typeof data === 'object' && Object.keys(data).length > 0) {
        const arr = Object.keys(data).map(item => `${item}=${encodeURI(data[item])}`);
        url += `?${arr.join('&')}`;
      }
      const fullUrl = this.prepareUrl(url);
      console.log(`fullUrl=${fullUrl}`);
      return this.$http.get(fullUrl, { headers })
        .then(result => result)
        .catch(function (e) {
          if (e.status === 401) {
            this.$session.destroy();
            this.$router.push('/login');
            return {
              body: {},
              status: e.status
            };
          }
          throw e;
        });
      },
    postData(url, data, options) {
      options = options || {};
      const headers = this.getHeaders();

      return this.$http.post(this.prepareUrl(url), data, { headers })
        .then(result => result)
        .catch(function (e) {
          if (e.status === 401 && !options.ignoreUnauthorized) {
            this.$router.push('/login');
            return;
          }
          console.error(e);
          throw e;
        });
    },
    putData(url, data) {
      const headers = this.getHeaders();

      return this.$http.put(this.prepareUrl(url), data, { headers })
        .then(result => result)
        .catch(function (e) {
          if (e.status === 401) {
            this.$router.push('/login');
            return;
          }
          throw e;
        });
    },
    patchData(url, data) {
      // console.log('patchData');
      const headers = this.getHeaders();
      return this.$http.patch(this.prepareUrl(url), data, { headers })
        .then(result => result)
        .catch(function (e) {
          if (e.status === 401) {
            this.$router.push('/login');
            return;
          }
          throw e;
        });
    },
    deleteData(url) {
      const headers = this.getHeaders();
      return this.$http.delete(this.prepareUrl(url), { headers })
        .then(result => result)
        .catch(function (e) {
          if (e.status === 401) {
            this.$router.push('/login');
            return { body: {} };
          }
          throw e;
        });
    },
    prepareUrl(url) {
      url = `/${url}`;
      url = process.env.VUE_APP_API + url.replace(/[/]{2,}/, '/');
      console.log(url);
      return url;
    },
    getHeaders() {
      if (!this.$session.exists()) {
        return {};
      }
      // return {};
      const token = this.$session.get('token');
      return {
        Authorization: 'Bearer ' + token
      };
    },
    getMask(bit) {
      if (!Number.isInteger(bit)) {
        bit = Number.parseInt(bit);
      }
      return 0b1 << (bit - 1);
    },
    isPermited(bit) {
      return (this.$session.get('permitions') & this.getMask(bit)) > 0;
    },
    $isActive() {
      return this.$session.get('active');
    }
  }
});

Vue.component('date-component', dateComponent);

Vue.use(VueI18n);
/* eslint-env browser  */
let lang = navigator.language.toLowerCase();

if (lang.match(/-/)) {
  [lang] = lang.split(/-/);
}
if (lang === 'uk') {
  lang = 'ru';
}
// lang='en';

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: {}
});

new Vue({
  router,
  data: settings,
  render: h => h(App),
  watch: {
    '$route' (/* to, from */) {
      if (this.$session.exists() || this.$route.meta.isPublic) {
        return;
      }
      this.$router.push('/login');
    }
  },
  i18n
  // components: {},
}).$mount('#app');
