<template>
    <nav class="navbar navbar-light navbar-expand-md d-flex flex-wrap nav-fill">
        <button class="navbar-toggler" @click="isMenu=!isMenu">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon"></span>
        </button>
        <div :class="{collapse: !isMenu}" class="navbar-collapse flex-grow-1 flex-shrink-1 justify-content-around">
            <ul class="nav navbar-nav flex-grow-1 justify-content-around">
                <router-link tag="li" class="nav-item" to="/admin/admins">
                    <a class="nav-link">Регистраторы</a>
                </router-link>
                <router-link tag="li" class="nav-item" to="/admin/retrts">
                    <a class="nav-link">Ретриты</a>
                </router-link>
                <router-link tag="li" class="nav-item" to="/admin/codes">
                    <a class="nav-link">СМС коды</a>
                </router-link>
                <!--
                <router-link tag="li" class="nav-item" to="/admin/bases">
                    <a class="nav-link">Базы</a>
                </router-link>
                -->
                <router-link tag="li" class="nav-item" to="/admin/discounts">
                    <a class="nav-link">Скидки</a>
                </router-link>
                <router-link tag="li" class="nav-item" to="/index">
                    <a class="nav-link">Главное меню</a>
                </router-link>
            </ul>
        </div>
    </nav>
</template>

<script>
  export default {
    data: function() {
      return {
        isMenu: false
      }
    },
    methods: {
      logout() {
        this.$session.destroy();
        this.$router.push('/login');
      }
    }
  }
</script>

<style scoped>

</style>
